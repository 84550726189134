.button {
  padding: 14px;
  border: 1px solid #333333;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;

  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.5px;

  min-width: 260px;
  max-width: 320px;
  border-radius: 6px;
  background-color: #333333;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);

  margin: 0 auto;

  -webkit-appearance: none;

  cursor: pointer;

  &--icon {
    padding: 5px 12px;
    background-color: transparent;
    border: 0;
    vertical-align: middle;
    cursor: pointer;
  }

  &--inverted {
    background-color: #fff;
    color: #333;
  }
}

a.button {
  text-align: center;
}

.link {
  padding: 5px 12px;
  color: #555;
  text-decoration: none;

  &__back {
    position: relative;

    text-align: left;

    background-color: transparent;
    border: none;
  }

  &__back-arrow {
    display: inline-block;
    width: 40px;
    height: 40px;

    position: relative;
    padding: 0;

    text-align: center;

    background-color: transparent;
    border: none;
  }
}

.svg-background {
  // display: inline-block;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  // object-fit: contain;
  border-radius: 15px;
  background-color: #f6f6f6;
}
