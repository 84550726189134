.page {
    max-width: 600px;
    margin: 0 auto;
    padding: 100px 20px;
    display: flex;
    overflow: auto;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;

    h1, p, h2 {
      text-align: center;
    }

    h2 {
      padding-top: 3rem;
    }

    &--noparking {
      > h2 {
        padding-top: 0;
        margin: 0 auto;
      }

      blockquote {
        margin-left: 0;

        blockquote {
          margin-right: 0;
        }
      }

      .attention {
        margin-bottom: 2rem;
      }

      &__sub-container {
        width: 80%;
        margin: 1rem auto;

        li {
          margin-bottom: 1rem;
        }

        p {
          margin: 0;
          text-align: left;
          font-size: 18px;
        }
      }

      .license-box {
        padding: 1.5rem 0;
      }
    }

    &__graphic {
      align-self: center;
      max-width: 180px;

      img {
        user-select: none;
        width: 100%;
      }
    }

    &--myparking {
      &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0 30px;

        &__status {
          margin: 2px 0;
        }
      }
    }
}
