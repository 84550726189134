// ibm-plex-sans-100 - latin
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: local('IBM Plex Sans Thin'), local('IBMPlexSans-Thin'),
    url('./fonts/ibm-plex-sans-v3-latin-100.woff2') format('woff2'),
    url('./fonts/ibm-plex-sans-v3-latin-100.woff') format('woff');
}
// ibm-plex-sans-100italic - latin
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  src: local('IBM Plex Sans Thin Italic'), local('IBMPlexSans-ThinItalic'),
    url('./fonts/ibm-plex-sans-v3-latin-100italic.woff2') format('woff2'),
    url('./fonts/ibm-plex-sans-v3-latin-100italic.woff') format('woff');
}
// ibm-plex-sans-200 - latin
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: local('IBM Plex Sans ExtraLight'), local('IBMPlexSans-ExtraLight'),
    url('./fonts/ibm-plex-sans-v3-latin-200.woff2') format('woff2'),
    url('./fonts/ibm-plex-sans-v3-latin-200.woff') format('woff');
}
// ibm-plex-sans-200italic - latin
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: local('IBM Plex Sans ExtraLight Italic'), local('IBMPlexSans-ExtraLightItalic'),
    url('./fonts/ibm-plex-sans-v3-latin-200italic.woff2') format('woff2'),
    url('./fonts/ibm-plex-sans-v3-latin-200italic.woff') format('woff');
}
// ibm-plex-sans-300 - latin
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local('IBM Plex Sans Light'), local('IBMPlexSans-Light'),
    url('./fonts/ibm-plex-sans-v3-latin-300.woff2') format('woff2'),
    url('./fonts/ibm-plex-sans-v3-latin-300.woff') format('woff');
}
// ibm-plex-sans-300italic - latin
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local('IBM Plex Sans Light Italic'), local('IBMPlexSans-LightItalic'),
    url('./fonts/ibm-plex-sans-v3-latin-300italic.woff2') format('woff2'),
    url('./fonts/ibm-plex-sans-v3-latin-300italic.woff') format('woff');
}
// ibm-plex-sans-regular - latin
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex Sans'), local('IBMPlexSans'),
    url('./fonts/ibm-plex-sans-v3-latin-regular.woff2') format('woff2'),
    url('./fonts/ibm-plex-sans-v3-latin-regular.woff') format('woff');
}
// ibm-plex-sans-italic - latin
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local('IBM Plex Sans Italic'), local('IBMPlexSans-Italic'),
    url('./fonts/ibm-plex-sans-v3-latin-italic.woff2') format('woff2'),
    url('./fonts/ibm-plex-sans-v3-latin-italic.woff') format('woff');
}
// ibm-plex-sans-500 - latin
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
    url('./fonts/ibm-plex-sans-v3-latin-500.woff2') format('woff2'),
    url('./fonts/ibm-plex-sans-v3-latin-500.woff') format('woff');
}
// ibm-plex-sans-500italic - latin
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: local('IBM Plex Sans Medium Italic'), local('IBMPlexSans-MediumItalic'),
    url('./fonts/ibm-plex-sans-v3-latin-500italic.woff2') format('woff2'),
    url('./fonts/ibm-plex-sans-v3-latin-500italic.woff') format('woff');
}
// ibm-plex-sans-600 - latin
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
    url('./fonts/ibm-plex-sans-v3-latin-600.woff2') format('woff2'),
    url('./fonts/ibm-plex-sans-v3-latin-600.woff') format('woff');
}
// ibm-plex-sans-600italic - latin
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local('IBM Plex Sans SemiBold Italic'), local('IBMPlexSans-SemiBoldItalic'),
    url('./fonts/ibm-plex-sans-v3-latin-600italic.woff2') format('woff2'),
    url('./fonts/ibm-plex-sans-v3-latin-600italic.woff') format('woff');
}
// ibm-plex-sans-700 - latin
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'),
    url('./fonts/ibm-plex-sans-v3-latin-700.woff2') format('woff2'),
    url('./fonts/ibm-plex-sans-v3-latin-700.woff') format('woff');
}
// ibm-plex-sans-700italic - latin
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: local('IBM Plex Sans Bold Italic'), local('IBMPlexSans-BoldItalic'),
    url('./fonts/ibm-plex-sans-v3-latin-700italic.woff2') format('woff2'),
    url('./fonts/ibm-plex-sans-v3-latin-700italic.woff') format('woff');
}

@font-face {
  font-family: 'ahamonoregular';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/ahamono-regular-webfont.woff2') format('woff2'),
    url('./fonts/ahamono-regular-webfont.woff') format('woff');
}

.text {
  color: #000;
  font-size: 16px;

  &--standard {
    font-size: 20px;
  }
}
