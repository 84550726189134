.modal {

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    width: 83%;
    background-color: #fff;
    box-shadow: 0 2rem 2rem rgba(#000, 0.2);
    border: 1px solid #ccc;
    padding: 1rem;

  }

  &__header {
    display: grid;
    grid-template-columns: auto min-content;
    padding-bottom: 1rem;
  }

  &__title {
    margin: 0;
    font-weight: 300;
    font-size: 1.6rem;
  }

  &__close {
    background: none;
    border: none;
    display: block;
    width: 30px;
    height: 30px;
    background: red;
    border-radius: 50%;
    background-color: #ddd;
    margin-left: 1rem;
    cursor: pointer;
    position: relative;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -55%);
      font-size: 1.6rem;
    }

    &:hover, &:active {
      background-color: #ccc;
    }

    &:active {
      transform: translateY(2px);

    }
  }

  &__body {
    .button {
      width: 100%;
      max-width: 500px;
    }

    .spinner {
      margin: 20px auto 50px auto;
    }
  }
}
