.footerItems {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    opacity: 0.7;
}

.footerItems li {
    margin: 0;
    padding: 0.2em 0;
    list-style: none;
    display: block;
    white-space: nowrap;
    text-align: center;
}

.footerItems a[target="_blank"] {
    text-decoration: none;
}

.footerItems a[target="_blank"]::after {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
    margin: 0 3px 0 5px;
}
