.support-box {
  display: flex;
  flex-direction: column;

  p:last-child {
    margin-top: 2rem;
    font-size: 0.9rem;
  }
}

.form {

  &--reg,
  &--pay,
  &--pay-card,
  &--access {
    .button {
      display: block;
      margin: 20px auto 0 auto;
    }
  }

  &--pay-card .button {
    margin-top: 0;
  }

  &--reg, &--pay {
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;

    input[type='text'], input[type='tel'] {
      padding: 5px 0 5px 30px;
      width: 100%;
      box-sizing: border-box;
    }

    svg {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &--email {
    .button {
      min-width: 300px;
      margin-top: 1rem;
    }
  }

  &__options {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  &__help-block {
    display: block;
    margin-bottom: 20px;
  }

  &--pay {
    width: 100%;
    padding-bottom: 20px;
  }

  &--pay-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

  }
}

.input-wrapper {
  position: relative;
  min-width: 300px;
  margin: 5px auto;

  svg {
    position: absolute;
    top: 33px;
    left: 0;
  }

  input {
    width: 100%;
    padding: 10px 14px 10px 30px;
    margin-bottom: 0;
    box-sizing: border-box;

  }

  &.invalid {
    input {
      border-bottom: 1px solid #fd7373;
    }
    path, rect, circle {
      stroke: #fd7373;
    }
  }
}

.checkbox-btn {
  flex: 1;

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
  }

  label {
    position: relative;
    display: inline-block;

    padding-left: 60px;

    width: 100%;
    box-sizing: border-box;
  }

  label::before,
  label::after {
    position: absolute;
    content: '';
    display: inline-block;
  }

  label::before {
    height: 40px;
    width: 40px;

    background-color: #fff;
    border-radius: 2px;
    border: solid 1px rgba(0, 0, 0, 0.3);
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }

  label::after {
    height: 10px;
    width: 20px;
    border-left: 2px solid;
    border-bottom: 2px solid;

    transform: rotate(-45deg) translateY(-50%);
    top: calc(50% - 5px);
    left: 25px;
  }

  input[type='checkbox'] + label::after {
    content: none;
  }

  input[type='checkbox']:checked + label::after {
    content: '';
  }

  &--disabled {
    .parkingbox__input {
      pointer-events: none;
    }
    label {
      pointer-events: none;
      &::before {
        background-color: #ccc;
      }
      &::after {
        border-color: #aaa;
      }
    }
  }
}

.radio-btn {
  position: relative;

  img {
    user-select: none;
  }

  & + .radio-btn {
    margin-left: 35px;
  }

  input[type='radio'] {
    opacity: 0;
    position: absolute;
  }

  label {
    position: relative;
    display: inline-block;
    padding-left: 22px;
    background-color: #fff;
    text-align: center;
    padding: 5px;
    display: block;
    box-sizing: border-box;
    width: 100%;
  }

  input[type='radio'] + label {
    border-color: #eee;
    background-color: #fff;
  }

  input[type='radio']:checked + label {
    border-color: #2980b9;
  }

  .label {
    &--image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      height: 70px;
      border-radius: 4px;
      border: solid 1px #eeeeee;
    }
  }
}




input[type='text'],
input[type='tel'],
input[type='email'] {
  outline: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #999;
  font-size: 17px;
  font-weight: 500;
  color: #333;
  -webkit-appearance: none;
}

input[type='text']::placeholder,
input[type='email']::placeholder,
input[type='tel']::placeholder {
  font-weight: 300;
  color: #ccc;
}

input[type='submit']:disabled, button:disabled {
  opacity: 0.7;
  pointer-events: none;
  cursor: not-allowed;
}
