.details-wrapper {
  width: 100%;

  h1 {
    text-align: center;
    margin-bottom: 10px;
  }
}

.details {
  border-radius: 2px;
  background-color: rgba(41, 128, 185, 0.08);
  padding: 10px;
  margin: 0;
  list-style: none;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  li {
    line-height: 2;
    font-weight: 700;
  }

  p {
    margin: 0;
    display: flex;
    font-weight: 300;

    >span {
      padding-left: 3px;
      font-weight: 400px;
    }

    span:first-child {
      flex-basis: 130px;
      font-weight: 700;
    }

    text-align: left;
  }

}

.parkingbox {
  border-radius: 2px;
  margin-bottom: 5px;
  padding-bottom: 1px;
  /*without this bottom margin breaks on children in firefox for some reason */
  background-color: rgba(41, 128, 185, 0.08);


  .highlighted {
    text-transform: uppercase;
    color: #ffa100;

    &::after {
      content: '' !important;
    }
  }

  p {
    text-align: left;
    margin: 0;
    line-height: 1.7;

    span:first-child {
      min-width: 130px;
    }
  }

  li>p span {
    font-weight: 700;
  }

  em {
    margin-left: 5px;
  }

  &__details {
    padding: 5px 0;

    >p {
      font-weight: 600;
      display: flex;

      >span:first-child {
        flex-basis: 90px;

        &::after {
          content: ':';
        }
      }

      >span:last-child {
        font-weight: 400;
        margin-left: 3px;
      }
    }
  }

  &__info {
    display: flex;
  }

  &__priceinfolink {
    text-align: right;
    padding: 0.5em 1em 0;
    margin-bottom: 0.2em;
    user-select: none;
    cursor: help;

    a {
      color: #0000EE;
      text-decoration: underline;
    }
  }

  &__priceinfo {
    margin: 0 1em 1em 1em;
    padding: 0.5em;
    background-color: rgba(255, 255, 255, 0.5);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding-top: 0;
      margin: 1em 0 0.25em;
      text-align: left;
    }

    h1:first-of-type,
    h2:first-of-type,
    h3:first-of-type,
    h4:first-of-type,
    h5:first-of-type,
    h6:first-of-type {
      padding-top: 0;
      margin: 0.25em 0 0.25em;
      text-align: left;
    }

    table {
      width: 100%;
      border-collapse: collapse; // 1
      border-spacing: 0;
      text-indent: 0; // 2
    }

    th,
    td {
      padding: 0.2em 0.4em;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      text-align: left;
      text-align: start;
    }

    li {
      margin: 0.5em;
    }

  }

  &__select {
    flex: 1;
    display: flex;
    position: relative;
  }

  &__input {
    flex: 0 0 40px;
  }

  &__button {
    width: 40px;
    border-radius: 2px;
    background-color: #f0f0f0;
    border: none;
    border-left: 1px solid #fff;
    padding: 0;
  }

  &__title {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.2px;
    margin: 10px 0 2px;
  }

  &__more {
    background-color: #fff;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);

    &__text {
      margin: 0;
      padding: 0 10px 10px;
      text-align: center;
    }
  }
}
