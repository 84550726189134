.header {
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);

  padding-left: env(safe-area-inset-left); // Fix for iPhone X
  padding-right: env(safe-area-inset-right); // Fix for iPhone X

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__column {
    flex-basis: 60px;
  }

  &__image {
    position: relative;
    padding: 10px;
    display: inline-block;
    margin: 0 auto;
    box-sizing: border-box;
    height: 46px;
    overflow: hidden;

    &:after {
      content: '';
      background-image: url('../assets/icons/img-placeholder.svg');
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: 2px;
      left: 0;
      width: 150px;
      height: 42px;
      background-color: #ddd;
    }
  }

  &__info .button:not(:last-of-type) {
    margin-right: 10px;
  }
}

