
.attention {
  border: 5px solid #ffc44d;
  font-weight: 700;
  text-align: center;
  margin: 0 auto;
  border-radius: 4px;
  padding: 1rem 2rem;
}

.divider {
  height: 1px;
  background-color: #000;
  margin: 1rem 0;
}

.licenseplate {
  position: relative;

  &__text {
    font-family: 'ahamonoregular';
    width: 150px;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    top: 10px;
    left: 0;
    padding-left: 26px;
    font-size: 30px;
    text-align: center;
  }

  &__subtext {
    height: 18px;
    opacity: 0.5;
    font-size: 14px;
    color: #000;
  }
}

.price {
  color: #e01f1f;
}

.clock {
  position: relative;

  &__hands {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__sec,
  &__min,
  &__hour {
    transform-origin: center;
  }
}

.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #333;

  border-radius: 100%;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.error {
  background-color: #ef5151;
  box-sizing: border-box;
  border: 1px solid #9e2a2a;
  border-radius: 5px;
  color: #fff;
  margin: 1rem auto;
  width: 80%;
  position: fixed;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);

  &__exclamation {
    position: absolute;
    border: 3px solid #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);

    &::after {
      content: '!';
      font-size: 20px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
    transform: rotate(45deg);

    &::after {
      content: '+';
      font-size: 30px;
      font-weight: 700;
    }
  }

  &__message {
    font-size: 14px;
    text-align: left !important;
    padding-left: 50px;
  }
}

.fade-appear,
.fade-enter {
  opacity: 0;
}

.fade-appear-active,
.fade-enter-active {
  transition: opacity 0.3s linear;
  opacity: 1;
}

.fade-exit {
  transition: opacity 0.2s linear;
  opacity: 1;

  &-active {
    opacity: 0;
  }
}

.success {
  color: #16af1e;
}

.success-symbol {
  font-size: 2rem;
  border: 0.2em solid #16af1e;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;

  &::after {
    content: '✓';
    color: #16af1e;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}

.error-symbol {
  font-size: 1.5rem;
  border: 0.2em solid #ef5151;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;

  &::after {
    content: '❌';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }
}

.radio {
  font-size: 1.1rem;
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 0.5em;
  padding: 0.5rem;
  font-weight: 400;
  width: 100%;

  &__input {
    display: flex;

    input {
      opacity: 0;
      width: 0;
      height: 0;
      position: absolute;

      &:checked + .radio__control {
        background: radial-gradient(#333 50%, rgba(255, 0, 0, 0) 50%)
      }
    }
  }

  &__control {
    display: block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: 0.1em solid #333;
    transform: translateY(-0.04em);
  }


  &__label {
    line-height: 1;

  }
}



