@import "https://fonts.googleapis.com/css?family=Raleway&display=swap";
:root {
  --light-grey: #f6f9fc;
  --dark-terminal-color: #0a2540;
  --accent-color: #635bff;
  --radius: 3px;
}

body {
  color: var(--dark-terminal-color);
  justify-content: center;
  padding: 20px;
  font-family: Raleway;
  font-size: 1.2em;
  display: flex;
}

main {
  width: 480px;
}

form > * {
  margin: 10px 0;
}

button {
  background-color: var(--accent-color);
  background: var(--accent-color);
  border-radius: var(--radius);
  color: #fff;
  cursor: pointer;
  border: 0;
  margin-top: 16px;
  padding: 12px 16px;
  font-weight: 600;
  transition: all .2s;
  display: block;
}

button:hover {
  filter: contrast(115%);
}

button:active {
  filter: brightness(.9);
  transform: translateY(0)scale(.98);
}

button:disabled {
  opacity: .5;
  cursor: none;
}

input, select {
  width: 100%;
  margin-bottom: 10px;
  font-size: 1.1em;
  display: block;
}

label {
  display: block;
}

a {
  color: var(--accent-color);
  font-weight: 900;
}

small {
  font-size: .6em;
}

fieldset, input, select {
  border: 1px solid #efefef;
}

#payment-form {
  border-radius: var(--radius);
  border: 1px solid #f6f9fc;
  margin: 20px 0;
  padding: 20px;
  box-shadow: 0 30px 50px -20px #32325d40, 0 30px 60px -30px #0000004d;
}

#messages {
  color: #00d924;
  border-radius: var(--radius);
  background-color: #0a253c;
  margin: 20px 0;
  padding: 20px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New;
  font-size: .7em;
  display: none;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Thin), local(IBMPlexSans-Thin), url("ibm-plex-sans-v3-latin-100.2c2b7dee.woff2") format("woff2"), url("ibm-plex-sans-v3-latin-100.315b56fd.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Thin Italic), local(IBMPlexSans-ThinItalic), url("ibm-plex-sans-v3-latin-100italic.4c1a5a47.woff2") format("woff2"), url("ibm-plex-sans-v3-latin-100italic.e7e06bfd.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans ExtraLight), local(IBMPlexSans-ExtraLight), url("ibm-plex-sans-v3-latin-200.0b84e99a.woff2") format("woff2"), url("ibm-plex-sans-v3-latin-200.7a205527.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans ExtraLight Italic), local(IBMPlexSans-ExtraLightItalic), url("ibm-plex-sans-v3-latin-200italic.66e7f897.woff2") format("woff2"), url("ibm-plex-sans-v3-latin-200italic.9c146074.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Light), local(IBMPlexSans-Light), url("ibm-plex-sans-v3-latin-300.303bba26.woff2") format("woff2"), url("ibm-plex-sans-v3-latin-300.4a1723d3.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Light Italic), local(IBMPlexSans-LightItalic), url("ibm-plex-sans-v3-latin-300italic.ef4eccc3.woff2") format("woff2"), url("ibm-plex-sans-v3-latin-300italic.f6094dbd.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans), local(IBMPlexSans), url("ibm-plex-sans-v3-latin-regular.83e75550.woff2") format("woff2"), url("ibm-plex-sans-v3-latin-regular.c000ab73.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Italic), local(IBMPlexSans-Italic), url("ibm-plex-sans-v3-latin-italic.1c64a318.woff2") format("woff2"), url("ibm-plex-sans-v3-latin-italic.b135d624.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Medium), local(IBMPlexSans-Medium), url("ibm-plex-sans-v3-latin-500.85fdfc07.woff2") format("woff2"), url("ibm-plex-sans-v3-latin-500.eb0754ef.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Medium Italic), local(IBMPlexSans-MediumItalic), url("ibm-plex-sans-v3-latin-500italic.f2d7358a.woff2") format("woff2"), url("ibm-plex-sans-v3-latin-500italic.a1981ad3.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans SemiBold), local(IBMPlexSans-SemiBold), url("ibm-plex-sans-v3-latin-600.999fe384.woff2") format("woff2"), url("ibm-plex-sans-v3-latin-600.6292aba4.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans SemiBold Italic), local(IBMPlexSans-SemiBoldItalic), url("ibm-plex-sans-v3-latin-600italic.52d11fbf.woff2") format("woff2"), url("ibm-plex-sans-v3-latin-600italic.fdfcd08e.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Bold), local(IBMPlexSans-Bold), url("ibm-plex-sans-v3-latin-700.d66c3625.woff2") format("woff2"), url("ibm-plex-sans-v3-latin-700.c40504c2.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Bold Italic), local(IBMPlexSans-BoldItalic), url("ibm-plex-sans-v3-latin-700italic.e7d07d03.woff2") format("woff2"), url("ibm-plex-sans-v3-latin-700italic.a37171a4.woff") format("woff");
}

@font-face {
  font-family: ahamonoregular;
  font-style: normal;
  font-weight: normal;
  src: url("ahamono-regular-webfont.008eee06.woff2") format("woff2"), url("ahamono-regular-webfont.6cdc994a.woff") format("woff");
}

.text {
  color: #000;
  font-size: 16px;
}

.text--standard {
  font-size: 20px;
}

.header {
  z-index: 9;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  background-color: #fff;
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  box-shadow: 0 0 40px #0000001a;
}

.header__content {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header__column {
  flex-basis: 60px;
}

.header__image {
  box-sizing: border-box;
  height: 46px;
  margin: 0 auto;
  padding: 10px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.header__image:after {
  content: "";
  width: 150px;
  height: 42px;
  background-color: #ddd;
  background-image: url("img-placeholder.78a0a77f.svg");
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 2px;
  left: 0;
}

.header__info .button:not(:last-of-type) {
  margin-right: 10px;
}

.page {
  max-width: 600px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 100px 20px;
  display: flex;
  overflow: auto;
}

.page h1, .page p, .page h2 {
  text-align: center;
}

.page h2 {
  padding-top: 3rem;
}

.page--noparking > h2 {
  margin: 0 auto;
  padding-top: 0;
}

.page--noparking blockquote {
  margin-left: 0;
}

.page--noparking blockquote blockquote {
  margin-right: 0;
}

.page--noparking .attention {
  margin-bottom: 2rem;
}

.page--noparking__sub-container {
  width: 80%;
  margin: 1rem auto;
}

.page--noparking__sub-container li {
  margin-bottom: 1rem;
}

.page--noparking__sub-container p {
  text-align: left;
  margin: 0;
  font-size: 18px;
}

.page--noparking .license-box {
  padding: 1.5rem 0;
}

.page__graphic {
  max-width: 180px;
  align-self: center;
}

.page__graphic img {
  user-select: none;
  width: 100%;
}

.page--myparking__header {
  justify-content: center;
  align-items: center;
  margin: 10px 0 30px;
  display: flex;
}

.page--myparking__header__status {
  margin: 2px 0;
}

.support-box {
  flex-direction: column;
  display: flex;
}

.support-box p:last-child {
  margin-top: 2rem;
  font-size: .9rem;
}

.form--reg .button, .form--pay .button, .form--pay-card .button, .form--access .button {
  margin: 20px auto 0;
  display: block;
}

.form--pay-card .button {
  margin-top: 0;
}

.form--reg, .form--pay {
  flex-direction: column;
  padding-bottom: 3rem;
  display: flex;
}

.form--reg input[type="text"], .form--reg input[type="tel"], .form--pay input[type="text"], .form--pay input[type="tel"] {
  width: 100%;
  box-sizing: border-box;
  padding: 5px 0 5px 30px;
}

.form--reg svg, .form--pay svg {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.form--email .button {
  min-width: 300px;
  margin-top: 1rem;
}

.form__options {
  justify-content: center;
  margin-bottom: 20px;
  display: flex;
}

.form__help-block {
  margin-bottom: 20px;
  display: block;
}

.form--pay {
  width: 100%;
  padding-bottom: 20px;
}

.form--pay-card {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  display: flex;
}

.input-wrapper {
  min-width: 300px;
  margin: 5px auto;
  position: relative;
}

.input-wrapper svg {
  position: absolute;
  top: 33px;
  left: 0;
}

.input-wrapper input {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0;
  padding: 10px 14px 10px 30px;
}

.input-wrapper.invalid input {
  border-bottom: 1px solid #fd7373;
}

.input-wrapper.invalid path, .input-wrapper.invalid rect, .input-wrapper.invalid circle {
  stroke: #fd7373;
}

.checkbox-btn {
  flex: 1;
}

.checkbox-btn input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.checkbox-btn label {
  width: 100%;
  box-sizing: border-box;
  padding-left: 60px;
  display: inline-block;
  position: relative;
}

.checkbox-btn label:before, .checkbox-btn label:after {
  content: "";
  display: inline-block;
  position: absolute;
}

.checkbox-btn label:before {
  height: 40px;
  width: 40px;
  background-color: #fff;
  border: 1px solid #0000004d;
  border-radius: 2px;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.checkbox-btn label:after {
  height: 10px;
  width: 20px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  top: calc(50% - 5px);
  left: 25px;
  transform: rotate(-45deg)translateY(-50%);
}

.checkbox-btn input[type="checkbox"] + label:after {
  content: none;
}

.checkbox-btn input[type="checkbox"]:checked + label:after {
  content: "";
}

.checkbox-btn--disabled .parkingbox__input, .checkbox-btn--disabled label {
  pointer-events: none;
}

.checkbox-btn--disabled label:before {
  background-color: #ccc;
}

.checkbox-btn--disabled label:after {
  border-color: #aaa;
}

.radio-btn {
  position: relative;
}

.radio-btn img {
  user-select: none;
}

.radio-btn + .radio-btn {
  margin-left: 35px;
}

.radio-btn input[type="radio"] {
  opacity: 0;
  position: absolute;
}

.radio-btn label {
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  padding: 5px;
  display: block;
  position: relative;
}

.radio-btn input[type="radio"] + label {
  background-color: #fff;
  border-color: #eee;
}

.radio-btn input[type="radio"]:checked + label {
  border-color: #2980b9;
}

.radio-btn .label--image {
  width: 150px;
  height: 70px;
  border: 1px solid #eee;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
}

input[type="text"], input[type="tel"], input[type="email"] {
  color: #333;
  -webkit-appearance: none;
  background-color: #0000;
  border: 0;
  border-bottom: 1px solid #999;
  border-radius: 0;
  outline: none;
  font-size: 17px;
  font-weight: 500;
}

input[type="text"]::placeholder, input[type="email"]::placeholder, input[type="tel"]::placeholder {
  color: #ccc;
  font-weight: 300;
}

input[type="submit"]:disabled, button:disabled {
  opacity: .7;
  pointer-events: none;
  cursor: not-allowed;
}

.attention {
  text-align: center;
  border: 5px solid #ffc44d;
  border-radius: 4px;
  margin: 0 auto;
  padding: 1rem 2rem;
  font-weight: 700;
}

.divider {
  height: 1px;
  background-color: #000;
  margin: 1rem 0;
}

.licenseplate {
  position: relative;
}

.licenseplate__text {
  width: 150px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  padding-left: 26px;
  font-family: ahamonoregular;
  font-size: 30px;
  position: absolute;
  top: 10px;
  left: 0;
}

.licenseplate__subtext {
  height: 18px;
  opacity: .5;
  color: #000;
  font-size: 14px;
}

.price {
  color: #e01f1f;
}

.clock {
  position: relative;
}

.clock__hands {
  position: absolute;
  top: 0;
  left: 0;
}

.clock__sec, .clock__min, .clock__hour {
  transform-origin: center;
}

.spinner {
  width: 40px;
  height: 40px;
  background-color: #333;
  border-radius: 100%;
  margin: 100px auto;
  animation: 1s ease-in-out infinite sk-scaleout;
}

@keyframes sk-scaleout {
  0% {
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.error {
  box-sizing: border-box;
  color: #fff;
  width: 80%;
  background-color: #ef5151;
  border: 1px solid #9e2a2a;
  border-radius: 5px;
  margin: 1rem auto;
  position: fixed;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);
}

.error__exclamation {
  width: 25px;
  height: 25px;
  border: 3px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.error__exclamation:after {
  content: "!";
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error__close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 5px;
  transform: rotate(45deg);
}

.error__close:after {
  content: "+";
  font-size: 30px;
  font-weight: 700;
}

.error__message {
  padding-left: 50px;
  font-size: 14px;
  text-align: left !important;
}

.fade-appear, .fade-enter {
  opacity: 0;
}

.fade-appear-active, .fade-enter-active {
  opacity: 1;
  transition: opacity .3s linear;
}

.fade-exit {
  opacity: 1;
  transition: opacity .2s linear;
}

.fade-exit-active {
  opacity: 0;
}

.success {
  color: #16af1e;
}

.success-symbol {
  width: 40px;
  height: 40px;
  border: .2em solid #16af1e;
  border-radius: 50%;
  font-size: 2rem;
  position: relative;
}

.success-symbol:after {
  content: "✓";
  color: #16af1e;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error-symbol {
  width: 40px;
  height: 40px;
  border: .2em solid #ef5151;
  border-radius: 50%;
  font-size: 1.5rem;
  position: relative;
}

.error-symbol:after {
  content: "❌";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.radio {
  grid-gap: .5em;
  width: 100%;
  grid-template-columns: min-content auto;
  padding: .5rem;
  font-size: 1.1rem;
  font-weight: 400;
  display: grid;
}

.radio__input {
  display: flex;
}

.radio__input input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.radio__input input:checked + .radio__control {
  background: radial-gradient(#333 50%, #f000 50%);
}

.radio__control {
  width: 1em;
  height: 1em;
  border: .1em solid #333;
  border-radius: 50%;
  display: block;
  transform: translateY(-.04em);
}

.radio__label {
  line-height: 1;
}

.button {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  min-width: 260px;
  max-width: 320px;
  -webkit-appearance: none;
  cursor: pointer;
  background-color: #333;
  border: 1px solid #333;
  border-radius: 6px;
  margin: 0 auto;
  padding: 14px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 5px 20px #0000001a;
}

.button--icon {
  vertical-align: middle;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  padding: 5px 12px;
}

.button--inverted {
  color: #333;
  background-color: #fff;
}

a.button {
  text-align: center;
}

.link {
  color: #555;
  padding: 5px 12px;
  text-decoration: none;
}

.link__back {
  text-align: left;
  background-color: #0000;
  border: none;
  position: relative;
}

.link__back-arrow {
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #0000;
  border: none;
  padding: 0;
  display: inline-block;
  position: relative;
}

.svg-background {
  width: 30px;
  height: 30px;
  background-color: #f6f6f6;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.stripe-frame {
  width: 100%;
  min-height: 600px;
  border: none;
  margin: 0 auto;
}

.modal__overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.modal__box {
  max-width: 500px;
  width: 83%;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 1rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2rem 2rem #0003;
}

.modal__header {
  grid-template-columns: auto min-content;
  padding-bottom: 1rem;
  display: grid;
}

.modal__title {
  margin: 0;
  font-size: 1.6rem;
  font-weight: 300;
}

.modal__close {
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: #ddd;
  border: none;
  border-radius: 50%;
  margin-left: 1rem;
  display: block;
  position: relative;
}

.modal__close span {
  font-size: 1.6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
}

.modal__close:hover, .modal__close:active {
  background-color: #ccc;
}

.modal__close:active {
  transform: translateY(2px);
}

.modal__body .button {
  width: 100%;
  max-width: 500px;
}

.modal__body .spinner {
  margin: 20px auto 50px;
}

.access > .button {
  margin: 20px auto;
  display: block;
}

.access__result > div {
  margin: 1rem auto;
}

.access__result > p {
  text-align: center;
  margin: 2rem;
  font-size: 1.6rem;
  font-weight: 600;
}

.access-list {
  padding: 0;
  list-style: none;
}

.access-list__item {
  border: 1px solid #ddd;
  justify-content: flex-start;
  align-items: center;
  margin: .5rem 0;
  display: flex;
  position: relative;
}

.details-wrapper {
  width: 100%;
}

.details-wrapper h1 {
  text-align: center;
  margin-bottom: 10px;
}

.details {
  background-color: #2980b914;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-radius: 2px;
  margin: 0;
  padding: 10px;
  list-style: none;
}

.details li {
  font-weight: 700;
  line-height: 2;
}

.details p {
  text-align: left;
  margin: 0;
  font-weight: 300;
  display: flex;
}

.details p > span {
  font-weight: 400px;
  padding-left: 3px;
}

.details p span:first-child {
  flex-basis: 130px;
  font-weight: 700;
}

.parkingbox {
  background-color: #2980b914;
  border-radius: 2px;
  margin-bottom: 5px;
  padding-bottom: 1px;
}

.parkingbox .highlighted {
  text-transform: uppercase;
  color: #ffa100;
}

.parkingbox .highlighted:after {
  content: "" !important;
}

.parkingbox p {
  text-align: left;
  margin: 0;
  line-height: 1.7;
}

.parkingbox p span:first-child {
  min-width: 130px;
}

.parkingbox li > p span {
  font-weight: 700;
}

.parkingbox em {
  margin-left: 5px;
}

.parkingbox__details {
  padding: 5px 0;
}

.parkingbox__details > p {
  font-weight: 600;
  display: flex;
}

.parkingbox__details > p > span:first-child {
  flex-basis: 90px;
}

.parkingbox__details > p > span:first-child:after {
  content: ":";
}

.parkingbox__details > p > span:last-child {
  margin-left: 3px;
  font-weight: 400;
}

.parkingbox__info {
  display: flex;
}

.parkingbox__priceinfolink {
  text-align: right;
  user-select: none;
  cursor: help;
  margin-bottom: .2em;
  padding: .5em 1em 0;
}

.parkingbox__priceinfolink a {
  color: #00e;
  text-decoration: underline;
}

.parkingbox__priceinfo {
  background-color: #ffffff80;
  margin: 0 1em 1em;
  padding: .5em;
}

.parkingbox__priceinfo h1, .parkingbox__priceinfo h2, .parkingbox__priceinfo h3, .parkingbox__priceinfo h4, .parkingbox__priceinfo h5, .parkingbox__priceinfo h6 {
  text-align: left;
  margin: 1em 0 .25em;
  padding-top: 0;
}

.parkingbox__priceinfo h1:first-of-type, .parkingbox__priceinfo h2:first-of-type, .parkingbox__priceinfo h3:first-of-type, .parkingbox__priceinfo h4:first-of-type, .parkingbox__priceinfo h5:first-of-type, .parkingbox__priceinfo h6:first-of-type {
  text-align: left;
  margin: .25em 0;
  padding-top: 0;
}

.parkingbox__priceinfo table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-indent: 0;
}

.parkingbox__priceinfo th, .parkingbox__priceinfo td {
  text-align: left;
  text-align: start;
  border-bottom: 1px solid #0000001a;
  padding: .2em .4em;
}

.parkingbox__priceinfo li {
  margin: .5em;
}

.parkingbox__select {
  flex: 1;
  display: flex;
  position: relative;
}

.parkingbox__input {
  flex: 0 0 40px;
}

.parkingbox__button {
  width: 40px;
  background-color: #f0f0f0;
  border: none;
  border-left: 1px solid #fff;
  border-radius: 2px;
  padding: 0;
}

.parkingbox__title {
  letter-spacing: -.2px;
  margin: 10px 0 2px;
  font-size: 15px;
  font-weight: 500;
}

.parkingbox__more {
  background-color: #fff;
  box-shadow: 0 20px 40px #0000001a;
}

.parkingbox__more__text {
  text-align: center;
  margin: 0;
  padding: 0 10px 10px;
}

.footerItems {
  opacity: .7;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.footerItems li {
  white-space: nowrap;
  text-align: center;
  margin: 0;
  padding: .2em 0;
  list-style: none;
  display: block;
}

.footerItems a[target="_blank"] {
  text-decoration: none;
}

.footerItems a[target="_blank"]:after {
  content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==");
  margin: 0 3px 0 5px;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

::-webkit-scrollbar {
  display: none;
}

body {
  color: #333;
  letter-spacing: normal;
  background-color: #fff;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1;
}

h1 {
  letter-spacing: -.5px;
  font-size: 2rem;
  font-weight: 700;
}

h2 {
  letter-spacing: -.3px;
  color: #000;
  font-size: 1.6rem;
  font-weight: 600;
}

h3 {
  letter-spacing: -.3px;
  color: #000;
  font-size: 1.6rem;
  font-weight: 500;
}

p {
  font-size: 1.1rem;
  line-height: 1.5;
}

label {
  font-size: 1.1rem;
  font-weight: 500;
}

img {
  vertical-align: middle;
}

a {
  color: #333;
}

.bold {
  font-weight: 700 !important;
}

.centered {
  text-align: center;
}

.link {
  color: #555;
  padding: 5px 12px;
  text-decoration: none;
}

.link__back {
  text-align: left;
  background-color: #0000;
  border: none;
  position: relative;
}

.link__back-arrow {
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #0000;
  border: none;
  padding: 0;
  display: inline-block;
  position: relative;
}

.svg-background {
  width: 30px;
  height: 30px;
  background-color: #f6f6f6;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

#root {
  height: 100%;
}

.license-box {
  text-align: center;
  padding: 4rem 0;
}

.license-box__plate {
  text-transform: uppercase;
  background-color: #f6f6f6;
  border-radius: 30px;
  padding: 0 25px;
  font-size: 34px;
  line-height: 1.5;
  display: inline-block;
}

/*# sourceMappingURL=index.a46095ce.css.map */
