@import 'typography',
'header',
'page',
'form',
'ui',
'button',
'iframe',
'modal',
'access-list',
'session',
'footer';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

*::-webkit-scrollbar {
  display: none;
}

body {
  background-color: #fff;
  color: #333;
  font-family: 'IBM Plex Sans';
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1;
}

h1 {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.5px;
}

h2 {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: -0.3px;
  color: #000;
}

h3 {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: #000;
}

p {
  font-size: 1.1rem;
  line-height: 1.5;
}

label {
  font-size: 1.1rem;
  font-weight: 500;
}

img {
  vertical-align: middle;
}

a {
  color: #333;
}

.bold {
  font-weight: 700 !important;
}

.centered {
  text-align: center;
}

.link {
  padding: 5px 12px;
  color: #555;
  text-decoration: none;

  &__back {
    position: relative;
    text-align: left;
    background-color: transparent;
    border: none;
  }

  &__back-arrow {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative;
    padding: 0;
    text-align: center;
    background-color: transparent;
    border: none;
  }
}

.svg-background {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #f6f6f6;
}

#root {
  height: 100%;
}

.license-box {
  text-align: center;
  padding: 4rem 0;

  &__plate {
    text-transform: uppercase;
    display: inline-block;
    font-size: 34px;
    line-height: 1.5;
    padding: 0 25px;
    border-radius: 30px;
    background-color: #f6f6f6;
  }

}
