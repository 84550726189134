.access {
  > .button {
    display: block;
    margin: 20px auto;
  }

  &__result {
    > div {
      margin: 1rem auto;
    }

    > p {
      font-size: 1.6rem;
      font-weight: 600;
      text-align: center;
      margin: 2rem;
    }
  }
}

.access-list {
  padding: 0;
  list-style: none;

  &__item {
    border: 1px solid #ddd;
    margin: 0.5rem 0;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
